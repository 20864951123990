import React, { useEffect, useState } from 'react';
import { Panel, Box } from '@tidbits/react-tidbits';
import UserAvatarFull from '@ui-components/user-avatar-full';
import { useSelector } from 'react-redux';
import { ReduxState } from '../../../../../common/types';
import AccordionList from '@ui-components/accordion-list';
import { getLoggedInUserProfile } from '@helper-hooks/query-hooks';
import { LoggedInUserProfileType, WorkspaceUserDetails } from '@providers/types-provider';
import { LoggedInUserProfileWorkspace } from '../LoggedInUserProfileWorskpace/LoggedInUserProfileWorkspace';

const LoggedInUserProfile = () => {
  const [activeAccordionName, setActiveAccordionName] = useState('');
  const user = useSelector((state: ReduxState) => state.user);
  const [loggedInUserProfileData, setLoggedInUserProfileData] = useState<LoggedInUserProfileType>();

  const handleAccordionClick = (name: string) => {
    activeAccordionName === name ? setActiveAccordionName('') : setActiveAccordionName(name);
  };

  const getProfileData = () => {
    try {
      getLoggedInUserProfile().then((data) => setLoggedInUserProfileData(data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  return (
    <Panel height="600px" maxHeight="600px" overflow="auto" data-testid="my-profile-component">
      <>
        <UserAvatarFull fullName={user.name} email={user.email} />
        <Box mt="30px">
          <AccordionList
            activeAccordionName={activeAccordionName}
            handleAccordionClick={handleAccordionClick}
          >
            {loggedInUserProfileData?.workspaceUserDetails.map(
              (workspace: WorkspaceUserDetails) => {
                return <LoggedInUserProfileWorkspace workspace={workspace} />;
              }
            )}
          </AccordionList>
        </Box>
      </>
    </Panel>
  );
};

export default LoggedInUserProfile;
