"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSetUnreadComments = exports.useAddCommentToTotalCommentsCount = void 0;
const types_provider_1 = require("@providers/types-provider");
exports.useAddCommentToTotalCommentsCount = (queryClient, batchSummary, threadId) => {
    return queryClient.setQueryData([types_provider_1.SharedQueryKeys.COLLABORATION_BATCH_SUMMARY], Object.assign(Object.assign({}, batchSummary), { [threadId]: Object.assign(Object.assign({}, batchSummary[threadId]), { unreadCommentsCount: 0, totalCommentsCount: batchSummary[threadId].totalCommentsCount + 1 }) }));
};
exports.useSetUnreadComments = (queryClient, batchSummary, threadId, value) => {
    return queryClient.setQueryData([types_provider_1.SharedQueryKeys.COLLABORATION_BATCH_SUMMARY], Object.assign(Object.assign({}, batchSummary), { [threadId]: Object.assign(Object.assign({}, batchSummary[threadId]), { unreadCommentsCount: value }) }));
};
