"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.putUserPreferencesData = exports.getUserPreferencesData = void 0;
exports.getUserPreferencesData = (userPreferenceResponse) => {
    let preferenceItemsList;
    if (userPreferenceResponse.hasOwnProperty('data')) {
        preferenceItemsList = userPreferenceResponse.data.preferenceItemsList;
    }
    else {
        preferenceItemsList = userPreferenceResponse.preferenceItemsList;
    }
    if ((preferenceItemsList === null || preferenceItemsList === void 0 ? void 0 : preferenceItemsList.length) === 1) {
        const preferences = preferenceItemsList.length && preferenceItemsList[0];
        return {
            [preferences.prefKey]: JSON.parse(preferences.payload)
        };
    }
    else if ((preferenceItemsList === null || preferenceItemsList === void 0 ? void 0 : preferenceItemsList.length) > 1) {
        return preferenceItemsList.reduce((userPreferenceObj, preference) => {
            userPreferenceObj[preference.prefKey] = JSON.parse(preference.payload);
            return userPreferenceObj;
        }, {});
    }
    else {
        return null;
    }
};
exports.putUserPreferencesData = (putUserPreferenceResponse) => {
    const updatedPreferences = {};
    const { data: { preferenceOperationStatusList } } = putUserPreferenceResponse;
    preferenceOperationStatusList === null || preferenceOperationStatusList === void 0 ? void 0 : preferenceOperationStatusList.forEach((userPreferenceItem) => {
        const { userPreferenceItemDto: { prefKey, payload } } = userPreferenceItem;
        updatedPreferences[prefKey] = JSON.parse(payload);
    });
    return updatedPreferences;
};
exports.default = {
    getUserPreferencesData: exports.getUserPreferencesData,
    putUserPreferencesData: exports.putUserPreferencesData
};
