import { createSelector } from 'reselect';
import { ReduxState } from '../../common/types';

export const userPreferencesSelector = (state: ReduxState) => state.userPreferences;

export const getUserPreferences = createSelector(
  userPreferencesSelector,
  (userPreferences) => userPreferences
);

export const getUserPreferencesWorklist = createSelector(
  userPreferencesSelector,
  (userPreferences) => userPreferences && userPreferences.worklist
);

export const getUserPreferencesBetaFeatures = createSelector(
  userPreferencesSelector,
  (userPreferences) => userPreferences && userPreferences.betaFeatures
);

export const getSettings = createSelector(
  userPreferencesSelector,
  (userPreferences) => userPreferences && userPreferences.settings
);

export const getPageSize = createSelector(
  userPreferencesSelector,
  (userPreferences) =>
    userPreferences && userPreferences.settings && userPreferences.settings.worklist.pageSize
);

export const sourceLocationSelector = createSelector(
  userPreferencesSelector,
  (userPreferences) =>
    userPreferences && userPreferences.settings && userPreferences.settings.task.sourceLocatedLeft
);

export const getUserPreferencesFilterPresets = createSelector(
  userPreferencesSelector,
  (userPreferences) => userPreferences && userPreferences.filterPresets
);
