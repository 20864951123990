"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCopyCheck = void 0;
const react_query_1 = require("react-query");
const api_1 = require("@helper-hooks/api");
const types_provider_1 = require("@providers/types-provider");
const getterForConfidenceScore = (payload) => api_1.api.confidenceScore.check(payload);
exports.useCopyCheck = (queryClient) => react_query_1.useMutation(getterForConfidenceScore, {
    onMutate: (payload) => __awaiter(void 0, void 0, void 0, function* () {
        return payload;
    }),
    onSuccess: (data) => {
        if (data) {
            queryClient.setQueryData(types_provider_1.SharedQueryKeys.COPY_CHECK, data);
        }
    }
});
