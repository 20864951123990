"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLoggedInUserProfile = exports.useFetchTaskHistory = exports.useSetUnreadComments = exports.useAddCommentToTotalCommentsCount = exports.useGetUserProfile = exports.useCopyCheckScore = exports.useCopyCheckSummary = exports.useCharacterCounts = exports.useVendor = exports.useSummary = exports.useBatchSummary = exports.useGetComments = exports.useLoggedInUser = exports.useUser = exports.useGetQueryData = exports.usePostQuery = exports.useGetQuery = exports.useCopyCheck = exports.useFetchComments = exports.useCopyChecks = exports.queryClientConfig = void 0;
const queryHookConfig_1 = require("./src/queryHookConfig");
Object.defineProperty(exports, "queryClientConfig", { enumerable: true, get: function () { return queryHookConfig_1.queryClientConfig; } });
const useCopyChecks_1 = require("./src/useCopyChecks");
Object.defineProperty(exports, "useCopyChecks", { enumerable: true, get: function () { return useCopyChecks_1.useCopyChecks; } });
const useFetchComments_1 = require("./src/useFetchComments");
Object.defineProperty(exports, "useFetchComments", { enumerable: true, get: function () { return useFetchComments_1.useFetchComments; } });
const useGetCopyCheck_1 = require("./src/useGetCopyCheck");
Object.defineProperty(exports, "useCopyCheck", { enumerable: true, get: function () { return useGetCopyCheck_1.useCopyCheck; } });
const useGetQuery_1 = require("./src/useGetQuery");
Object.defineProperty(exports, "useGetQuery", { enumerable: true, get: function () { return useGetQuery_1.useGetQuery; } });
Object.defineProperty(exports, "usePostQuery", { enumerable: true, get: function () { return useGetQuery_1.usePostQuery; } });
const useGetSharedQueryData_1 = require("./src/useGetSharedQueryData");
Object.defineProperty(exports, "useGetQueryData", { enumerable: true, get: function () { return useGetSharedQueryData_1.useGetQueryData; } });
Object.defineProperty(exports, "useUser", { enumerable: true, get: function () { return useGetSharedQueryData_1.useUser; } });
Object.defineProperty(exports, "useLoggedInUser", { enumerable: true, get: function () { return useGetSharedQueryData_1.useLoggedInUser; } });
Object.defineProperty(exports, "useGetComments", { enumerable: true, get: function () { return useGetSharedQueryData_1.useGetComments; } });
Object.defineProperty(exports, "useBatchSummary", { enumerable: true, get: function () { return useGetSharedQueryData_1.useBatchSummary; } });
Object.defineProperty(exports, "useSummary", { enumerable: true, get: function () { return useGetSharedQueryData_1.useSummary; } });
Object.defineProperty(exports, "useVendor", { enumerable: true, get: function () { return useGetSharedQueryData_1.useVendor; } });
Object.defineProperty(exports, "useCharacterCounts", { enumerable: true, get: function () { return useGetSharedQueryData_1.useCharacterCounts; } });
Object.defineProperty(exports, "useCopyCheckSummary", { enumerable: true, get: function () { return useGetSharedQueryData_1.useCopyCheckSummary; } });
Object.defineProperty(exports, "useCopyCheckScore", { enumerable: true, get: function () { return useGetSharedQueryData_1.useCopyCheckScore; } });
const useGetUserProfile_1 = require("./src/useGetUserProfile");
Object.defineProperty(exports, "useGetUserProfile", { enumerable: true, get: function () { return useGetUserProfile_1.useGetUserProfile; } });
const useSetComments_1 = require("./src/useSetComments");
Object.defineProperty(exports, "useAddCommentToTotalCommentsCount", { enumerable: true, get: function () { return useSetComments_1.useAddCommentToTotalCommentsCount; } });
Object.defineProperty(exports, "useSetUnreadComments", { enumerable: true, get: function () { return useSetComments_1.useSetUnreadComments; } });
const useFetchTaskHistory_1 = require("./src/useFetchTaskHistory");
Object.defineProperty(exports, "useFetchTaskHistory", { enumerable: true, get: function () { return useFetchTaskHistory_1.useFetchTaskHistory; } });
const useGetLoggedInUserProfile_1 = require("./src/useGetLoggedInUserProfile");
Object.defineProperty(exports, "getLoggedInUserProfile", { enumerable: true, get: function () { return useGetLoggedInUserProfile_1.getLoggedInUserProfile; } });
