"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCopyChecks = void 0;
const useGetSharedQueryData_1 = require("./useGetSharedQueryData");
exports.useCopyChecks = (useQueryClient, inputState) => {
    const { text } = inputState;
    const copyCheckSummary = useGetSharedQueryData_1.useCopyCheckSummary(useQueryClient);
    const confidenceScore = copyCheckSummary.score;
    return {
        copyCheckSummary,
        confidenceScore,
        text
    };
};
