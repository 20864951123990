export const COPY_WORKFLOW_READ_FLAG_PERMISSONS = [
  'CW_CAN_DO_WORKFLOW_SUPER_ADMIN',
  'CW_CAN_DO_WORKFLOW_LOC_PRODUCER',
  'CW_CAN_DO_WORKFLOW_VENDOR_PM',
  'CW_CAN_DO_WORKFLOW_BUSINESS_TEAM',
  'CW_CAN_DO_WORKFLOW_EXTERNAL_WRITER',
  'CW_CAN_DO_WORKFLOW_EXTERNAL_EDITOR',
  'CW_CAN_DO_WORKFLOW_INTERNAL_REVIEWER',
  'CW_CAN_DO_WORKFLOW_LANGUAGE_LEAD'
];

export const VCW_READ_FLAG_PERMISSIONS = [
  'VCW_CAN_DO_WORKFLOW_SUPER_ADMIN',
  'VCW_CAN_DO_WORKFLOW_LOC_PRODUCER',
  'VCW_CAN_DO_WORKFLOW_MANAGING_EDITOR_US',
  'VCW_CAN_DO_WORKFLOW_MANAGING_EDITOR_INT',
  'VCW_CAN_DO_WORKFLOW_TASK_DRI',
  'VCW_CAN_DO_WORKFLOW_GEO_LEAD',
  'VCW_CAN_DO_WORKFLOW_INTERNAL_REVIEWER',
  'VCW_CAN_DO_WORKFLOW_INTERNAL_WRITER',
  'VCW_CAN_DO_WORKFLOW_VENDOR_PM',
  'VCW_CAN_DO_WORKFLOW_VENDOR_EDITOR',
  'VCW_CAN_DO_WORKFLOW_VENDOR_WRITER'
];

export const MCW_READ_FLAG_PERMISSIONS = [
  'MCW_CAN_DO_WORKFLOW_SUPER_ADMIN',
  'MCW_CAN_DO_WORKFLOW_LOC_PRODUCER',
  'MCW_CAN_DO_WORKFLOW_TASK_DRI',
  'MCW_CAN_DO_WORKFLOW_MANAGING_EDITOR',
  'MCW_CAN_DO_WORKFLOW_GEO_LEAD',
  'MCW_CAN_DO_WORKFLOW_INTERNAL_REVIEWER',
  'MCW_CAN_DO_WORKFLOW_INTERNAL_WRITER',
  'MCW_CAN_DO_WORKFLOW_VENDOR_PM',
  'MCW_CAN_DO_WORKFLOW_VENDOR_EDITOR',
  'MCW_CAN_DO_WORKFLOW_VENDOR_WRITER'
];

export const ACW_READ_FLAG_PERMISSIONS = [
  'ACW_CAN_DO_WORKFLOW_SUPER_ADMIN',
  'ACW_CAN_DO_WORKFLOW_LOC_PRODUCER',
  'ACW_CAN_DO_WORKFLOW_VENDOR_PM',
  'ACW_CAN_DO_WORKFLOW_TASK_DRI',
  'ACW_CAN_DO_WORKFLOW_LANGUAGE_LEAD',
  'ACW_CAN_DO_WORKFLOW_ONSITE_REVIEWER',
  'ACW_CAN_DO_WORKFLOW_VENDOR_EDITOR',
  'ACW_CAN_DO_WORKFLOW_VENDOR_WRITER'
];

export const ALL_READ_FLAG_PERMISSIONS = [
  ...COPY_WORKFLOW_READ_FLAG_PERMISSONS,
  ...VCW_READ_FLAG_PERMISSIONS,
  ...MCW_READ_FLAG_PERMISSIONS,
  ...ACW_READ_FLAG_PERMISSIONS
];

export const ALL_ARCHIVE_PERMISSIONS = [
  'ACW_CAN_DO_WORKFLOW_ARCHIVE',
  'CW_CAN_DO_WORKFLOW_ARCHIVE',
  'MCW_CAN_DO_WORKFLOW_ARCHIVE',
  'VCW_CAN_DO_WORKFLOW_ARCHIVE'
];

export const ADMIN_PERMISSIONS = [
  'CW_CAN_DO_WORKFLOW_SUPER_ADMIN',
  'VCW_CAN_DO_WORKFLOW_SUPER_ADMIN',
  'MCW_CAN_DO_WORKFLOW_SUPER_ADMIN',
  'ACW_CAN_DO_WORKFLOW_SUPER_ADMIN'
];
