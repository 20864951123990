import { cloneDeep } from 'lodash';
import { TaskListItem, TaskListItemV1 } from '../../common/types/TaskList';
import { userHasPermission } from '@helper-hooks/general-utils';
import { ALL_READ_FLAG_PERMISSIONS } from './constants';

export const updateSelectedWorklistItemsByAttribute = (
  currentWorklistItems: TaskListItemV1[],
  selectedIds: string[],
  updateAttribute: string,
  updateValue: string | boolean
) => {
  const currentWorklistItemsClone = cloneDeep(currentWorklistItems);
  currentWorklistItemsClone.map((worklistItem: any) => {
    const updateKey = updateAttribute as keyof TaskListItemV1;
    if (selectedIds.includes(worklistItem.taskGuuid)) {
      worklistItem[updateKey] = updateValue;
      return worklistItem;
    }
    return worklistItem;
  });
  return currentWorklistItemsClone;
};

export const userHasPermissionToMTAR = (userPermissions: string[]) => {
  return userHasPermission(userPermissions, ALL_READ_FLAG_PERMISSIONS);
};

export const disableCommandFlagAction = (selectedTasks: TaskListItem[], action: string) => {
  if (action === 'archive') {
    const task = isTaskInTaskStatus(selectedTasks, ['COMPLETED', 'ARCHIVED', 'CANCELED']);
    return task ? true : false;
  }

  if (action === 'unarchive') {
    const taskCannotBeUnarchived = selectedTasks.find((tasks: TaskListItem) => {
      return !['ARCHIVED'].includes(tasks.taskStatus);
    });
    return taskCannotBeUnarchived ? true : false;
  }

  if (action === 'read' || action === 'unread') {
    if (isTasksNotHaveReadFlag(selectedTasks)) {
      return true;
    }

    const task = isTaskInTaskStatus(selectedTasks, [
      'COMPLETED',
      'ARCHIVED',
      'ERRORED',
      'POPULARITY',
      'CANCELED',
      'ANALYZE',
      'PUBLISH_ERROR',
      'PENDING',
      'ON_HOLD'
    ]);
    return task ? true : false;
  }

  return false;
};

export const isTaskInTaskStatus = (selectedTasks: TaskListItem[], taskStatus: string[]) => {
  const task = selectedTasks.find((task: TaskListItem) => {
    return [...taskStatus].includes(task.taskStatus);
  });
  return task;
};

/**
 * Validates if task has read flag or not
 */
export const isTasksNotHaveReadFlag = (selectedTasks: TaskListItem[]) => {
  const task = selectedTasks.find((task: TaskListItem) => {
    return !task.hasIsRead_boolProperty;
  });
  return task;
};
