import { Skill, WorkspaceUserDetails } from '@providers/types-provider';
import React from 'react';
import { Text } from '@tidbits/react-tidbits';
import AccordionList from '@ui-components/accordion-list';
import styled from 'styled-components';

const AccordionTitleContainer = styled.div`
  padding: 8px;
`;

const WorkspaceContainer = styled.div`
  background-color: white;
  border-top: 1px #d6d6d6 solid;
  border-left: 1px #d6d6d6 solid;
  border-right: 1px #d6d6d6 solid;
  margin-bottom: 8px;
`;

const WorkspaceBody = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const WorkspaceSection = styled.div`
  padding: 6px;
  flex-grow: 1;
`;

const SkillContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
  max-width: 390px;
`;

const LocalesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
`;

const AccordionTitle = ({ title }: { title: string }) => {
  return (
    <AccordionTitleContainer>
      <Text color="labelPlaceholder" mb="3px">
        Workspace
      </Text>
      <Text textStyle="h5Emph" color="title">
        {title}
      </Text>
    </AccordionTitleContainer>
  );
};

interface Props {
  workspace: WorkspaceUserDetails;
}

export const LoggedInUserProfileWorkspace = ({ workspace }: Props) => {
  return (
    <WorkspaceContainer key={workspace.workspaceName}>
      <AccordionList.Accordion
        title={<AccordionTitle title={workspace.workspaceName} key={workspace.workspaceName} />}
        name={workspace.workspaceName}
      >
        <WorkspaceBody>
          <WorkspaceSection>
            <Text textStyle="bodyRegular" color="labelPlaceholder" mb="8px">
              Skills
            </Text>
            {workspace.skills.map((skill: Skill, i) => {
              const hasLocales = skill.locales.length > 0;

              return (
                <SkillContainer key={skill.skillName}>
                  <Text textStyle="bodyRegular">{skill.skillName}</Text>
                  <LocalesContainer>
                    <Text textStyle="bodySmallRegular" color="labelPlaceholder">
                      {hasLocales ? ' (' : ''}
                    </Text>
                    {skill.locales.map((locale, i) => (
                      <Text
                        key={locale.identifier}
                        textStyle="bodySmallRegular"
                        color="labelPlaceholder"
                      >
                        {`${locale.displayName}${i === skill.locales.length - 1 ? '' : ', '}`}
                      </Text>
                    ))}
                    <Text textStyle="bodySmallRegular" color="labelPlaceholder">
                      {hasLocales ? ')' : ''}
                    </Text>
                  </LocalesContainer>
                </SkillContainer>
              );
            })}
          </WorkspaceSection>
          <WorkspaceSection>
            <Text textStyle="bodyRegular" color="labelPlaceholder" mb="8px">
              Vendor Company
            </Text>
            <Text mb="8px" textStyle="bodyRegular">
              {workspace.vendor}
            </Text>
          </WorkspaceSection>
        </WorkspaceBody>
      </AccordionList.Accordion>
    </WorkspaceContainer>
  );
};
