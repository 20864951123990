"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCopyCheckScore = exports.useCopyCheckSummary = exports.useCharacterCounts = exports.useVendor = exports.useSummary = exports.useBatchSummary = exports.useGetComments = exports.useLoggedInUser = exports.useUser = exports.useGetQueryData = void 0;
const text_utils_1 = require("@helper-hooks/text-utils");
const types_provider_1 = require("@providers/types-provider");
exports.useGetQueryData = (useQueryClient, queryKey, queryFilters) => {
    const queryClient = useQueryClient();
    const data = queryClient.getQueryData([queryKey, queryFilters], {
        exact: false
    });
    return data;
};
exports.useUser = (useQueryClient) => {
    const data = exports.useGetQueryData(useQueryClient, types_provider_1.SharedQueryKeys.VENDOR);
    if (!data)
        throw new Error('No User Data!');
    return data;
};
exports.useLoggedInUser = (useQueryClient) => {
    const data = exports.useGetQueryData(useQueryClient, types_provider_1.SharedQueryKeys.USER);
    if (!data)
        throw new Error('No LoggedIn User Data!');
    return data;
};
exports.useGetComments = (useQueryClient) => {
    const data = exports.useGetQueryData(useQueryClient, types_provider_1.SharedQueryKeys.COMMENTS);
    if (!data)
        throw new Error('No Comments Data!');
    return data;
};
exports.useBatchSummary = (useQueryClient) => {
    const data = exports.useGetQueryData(useQueryClient, types_provider_1.SharedQueryKeys.COLLABORATION_BATCH_SUMMARY);
    if (!data)
        return {};
    return data;
};
exports.useSummary = (useQueryClient, threadId) => {
    const data = exports.useBatchSummary(useQueryClient);
    const summary = data[threadId];
    return summary;
};
exports.useVendor = (useQueryClient) => {
    return exports.useUser(useQueryClient).vendor;
};
exports.useCharacterCounts = (useQueryClient) => {
    const key = types_provider_1.SharedQueryKeys.CHARACTER_COUNTS;
    const data = exports.useGetQueryData(useQueryClient, key);
    const mappedCharacters = data && text_utils_1.convertUnicodeListToMap(data);
    return mappedCharacters;
};
exports.useCopyCheckSummary = (useQueryClient) => {
    const data = exports.useGetQueryData(useQueryClient, types_provider_1.SharedQueryKeys.COPY_CHECK);
    if (!data)
        return {};
    return data;
};
exports.useCopyCheckScore = (useQueryClient) => {
    const data = exports.useCopyCheckSummary(useQueryClient);
    return data.score;
};
